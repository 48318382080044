import { Router } from '@angular/router';
import { BasePage } from './../base/BasePage';
import { Component, OnInit } from '@angular/core';
import { LoginPositionService } from './login-position.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login-position',
  templateUrl: './login-position.component.html',
  styleUrls: ['./login-position.component.css']
})
export class LoginPositionComponent extends BasePage implements OnInit {

  positionList : any;
  otherPositionList : any;
  career = new Array();

  constructor(public messageService: MessageService,
              public service : LoginPositionService,
              public router : Router) {
    super(messageService);
  }

  ngOnInit() {
    this.config.proSkills = new Array();

    this.service.init(this);
    this.service.getRecommended();
    this.service.getOther();
  }

  setList(list : any){
    this.positionList = list;

    for(let item of this.positionList){
      for(let value of this.config.proSkills){
        if(item.code == value.code){
          item.selected = true;
        }else{
          item.selected = false;
        }
      }
    }
  }

  setOtherList(list : any){
    this.otherPositionList = list;
    for(let item of this.otherPositionList){
      for(let value of this.config.proSkills){
        if(item.code == value.code){
          item.selected = true;
        }else{
          item.selected = false;
        }
      }
    }
  }

  select(item : any){
    item.selected = !item.selected;

    let exist = false;
    let num;
    for(let i = 0;i < this.config.proSkills.length;i++){
      if(item.code == this.config.proSkills[i].code){
        exist = true;
        num = i;
        break;
      }
    }

    if(!exist){
      if(this.config.proSkills.length < 5){
        this.config.proSkills.push(item);
      }else{
        item.selected = false;
        this.showMsg("最多只能选择5种职位");
      }
    }else{
      item.selected = false;
      this.config.proSkills.splice(num,1);
    }
    console.log(this.config.proSkills);
  }

  done() {
    //进入 职位技能选择
    this.router.navigateByUrl('userpositionskill');
  }

  next(){
    console.log(1111)
    if(this.config.proSkills.length > 0){
      for(let item of this.config.proSkills){
        this.career.push(item.code);
      }
      this.service.submit(JSON.stringify(this.career));
    }else if((!this.positionList || !this.positionList.length)&&(!this.otherPositionList ||!this.otherPositionList.length)){
      this.router.navigateByUrl('workspace');
    }else{
      this.showMsg("请选择您心仪的方向");
    }
  }
}
